import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import { X, Check } from 'react-feather';
import {
    combine,
    validateEmail,
    isRequired,
    Field,
    TextInput,
    Checkbox
} from '@corratech/form-components';
import { Form, useFormApi } from 'informed';
import {
    useLoadingIndicator,
    useGlobalOptions
} from '@corratech/context-provider';
import { useReCaptchaStoreConfig } from 'ModulesPath/Checkout/useReCaptchaStoreConfig';
import { ReCaptcha } from 'react-recaptcha-v3';
import loadReCaptcha from '@corratech/google-recaptcha-v3/src/loadReCaptcha';
import { RenderApolloError } from '@corratech/render-apollo-error';
import { SubmitButton } from './SubmitButton';
require('./SubscribeForm.less');
import { useDataLayerAction } from '@corratech/tag-manager';

export const SubscribeForm = props => {
    const [t] = useTranslation();
    const {
        useNewsletterSubscription,
        ErrorIcon,
        CheckIcon,
        placeholder,
        buttonLabel,
        subscriptionSuccess,
        agreementText
    } = props;
    const [messageType, setMessageType] = useState('Success');
    const SUCCESS = undefined; //Informed validation requires undfined for sucess validation
    const LoadingIndicator = useLoadingIndicator();
    let formData;
    const formApiRef = useRef(null);
    const setFormApi = useCallback(api => (formApiRef.current = api), []);
    const dataLayerAction = useDataLayerAction();

    //ReCaptcha
    const options = useGlobalOptions();
    const { actionV3FooterEmailSignup } = useReCaptchaStoreConfig();
    const [isReCaptchaLoaded, setIsReCaptchaLoaded] = useState(false);
    const [reCaptchaToken, setReCaptchaToken] = useState(null);
    const [captchaInvalidMsg, setCaptchaInvalidMsg] = useState(null);

    let recaptchaV3Publickey = '';
    let isV3FooterEmailSignupEnable = null;

    if (options.storeConfig && options.storeConfig.recaptcha_v3_public_key) {
        recaptchaV3Publickey = options.storeConfig.recaptcha_v3_public_key;
    }

    if (
        actionV3FooterEmailSignup &&
        actionV3FooterEmailSignup === 'recaptcha_v3'
    ) {
        isV3FooterEmailSignupEnable = true;
    } else {
        isV3FooterEmailSignupEnable = false;
    }

    useEffect(() => {
        const scriptLoaded = loadReCaptcha(recaptchaV3Publickey, () => {
            setIsReCaptchaLoaded(true);
        });
        if (!scriptLoaded) {
            setIsReCaptchaLoaded(true);
        }
    }, [recaptchaV3Publickey]);

    const verifyReCaptchaTokenFooterEmailSignupCallback = token => {
        setReCaptchaToken(token);
        setInterval(function() {
            resetReCaptchaTokenFooterEmailSignup();
        }, 100 * 1000);
    };

    const resetReCaptchaTokenFooterEmailSignup = () => {
        if (window.grecaptcha !== undefined) {
            window.grecaptcha
                .execute(recaptchaV3Publickey, {
                    action: 'footer_email_signup'
                })
                .then(function(token) {
                    setReCaptchaToken(token);
                });
        }
    };

    const [
        addNewsletterSubscriber,
        { loading: mutationLoading, message, error }
    ] = useNewsletterSubscription(
        {
            //on successful completion:
            onCompleted: () => {
                setMessageType('success');
                dataLayerAction({
                    type: 'FORM_SUBMIT',
                    data: {
                        formName: 'newsletter',
                        formType: '',
                        formCategory: ''
                    }
                });
            },
            onError: error => {
                setMessageType('error'); //handle error
                let reCaptchaFailure = false;
                let captchaInvalidMsg = null;

                if (error?.graphQLErrors) {
                    for (var idx = 0; idx < error.graphQLErrors.length; idx++) {
                        if (
                            error.graphQLErrors[idx]?.extensions?.category ===
                            'graphql-recaptcha'
                        ) {
                            captchaInvalidMsg =
                                error.graphQLErrors[idx]?.message;
                            reCaptchaFailure = true;
                        }
                    }
                }

                if (!reCaptchaFailure) {
                    resetReCaptchaTokenFooterEmailSignup();
                }

                if (captchaInvalidMsg) {
                    setCaptchaInvalidMsg(captchaInvalidMsg);
                }
            }
        },
        reCaptchaToken
    );

    const validateCheckbox = (value, values, text) => {
        return !value ? text : SUCCESS;
    };

    const messageDOM = messageType === 'success' && (
        <Alert variant={'success'}>
            <Check size="14" strokeWidth={'4'} color={'#000'} />
            {subscriptionSuccess ? subscriptionSuccess : t(message)}
        </Alert>
    );

    const onSubmit = formApi => {
        formData = formApi;
        setMessageType(null);
        formApi.submitForm();
        resetReCaptchaTokenFooterEmailSignup();
    };

    const handleFormSubmit = async () => {
        const values = formData.getValues();

        if (values.email) {
            await addNewsletterSubscriber(values.email).then(response => {
                formData.reset();
            });
        }
    };

    if (!isReCaptchaLoaded) {
        return <LoadingIndicator />;
    }

    const onEmailChange = (value) => {
        if (!value) return;

        const valueWithoutSpaces = (value || '').trim() === '' ? '' : value.replace(/\s+/g, '');
        const {current: formApi} = formApiRef;
        const fieldName = 'email';

        if (formApi) {
            const currentEmailValue = formApi.getValue(fieldName);

            if (currentEmailValue && currentEmailValue !== valueWithoutSpaces) {
                formApi.setValue(fieldName, valueWithoutSpaces);
            }
        }
    };

    return (
        <Form onSubmit={handleFormSubmit} getApi={setFormApi}>
            {isV3FooterEmailSignupEnable && (
                <ReCaptcha
                    action="footer_email_signup"
                    sitekey={recaptchaV3Publickey}
                    verifyCallback={
                        verifyReCaptchaTokenFooterEmailSignupCallback
                    }
                />
            )}
            <div
                className={`footer-subscription-wrapper ${props.className ||
                    ''}`}
            >
                <strong
                    role="heading"
                    aria-level="2"
                    className={'footer-top-heading'}
                >
                    {props.title}
                </strong>
                <Field label={t(props.inputLabel)}>
                    <TextInput
                        type={'email'}
                        title={t(props.inputLabel)}
                        field="email"
                        inputMode="email"
                        validate={combine([
                            {
                                fn: validateEmail,
                                text: t(props.invalidEmailText)
                            }
                        ])}
                        id={'email-footer'}
                        validateOnChange
                        validateOnBlur
                        placeholder={placeholder}
                        onChange={(e) => {
                            onEmailChange( e.target.value)
                        }}
                    />
                </Field>
                {props.hasAgreement && (
                    <div className="agreement-checkbox">
                        <Checkbox
                            field="subscription_agreement"
                            id="subscription_agreement"
                            validate={combine([
                                {
                                    fn: validateCheckbox,
                                    text: t(props.requiredText)
                                }
                            ])}
                            label={agreementText}
                        />
                    </div>
                )}
                {message && messageDOM}
                {captchaInvalidMsg == null && error && messageType === 'error' && (
                    <Alert variant={'danger'}>
                        <ErrorIcon size={9} color={'#B70020'} />
                        <RenderApolloError error={error} />
                    </Alert>
                )}
                {isV3FooterEmailSignupEnable && captchaInvalidMsg && (
                    <Alert variant="danger">
                        <ErrorIcon size={9} color={'#B70020'} />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: captchaInvalidMsg
                            }}
                        />
                    </Alert>
                )}
                <SubmitButton
                    onSubmit={onSubmit}
                    loading={mutationLoading}
                    btnLabel={buttonLabel}
                />
            </div>
        </Form>
    );
};

SubscribeForm.propTypes = {
    title: PropTypes.string,
    requiredText: PropTypes.string,
    invalidEmailText: PropTypes.string,
    inputLabel: PropTypes.string,
    useNewsletterSubscription: PropTypes.func,
    hasAgreement: PropTypes.bool,
    ErrorIcon: PropTypes.func,
    CheckIcon: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
    placeholder: PropTypes.string,
    buttonLabel: PropTypes.string,
    subscriptionSuccess: PropTypes.string
};

SubscribeForm.defaultProps = {
    requiredText: 'This is a required field.',
    invalidEmailText: 'Please enter a valid email, such as example@example.com',
    inputLabel: 'Enter your email address',
    hasAgreement: false,
    agreementText: 'I accept the terms and conditions of this subscription*',
    ErrorIcon: X,
    CheckIcon: Check,
    placeholder: 'Enter email',
    buttonLabel: 'Subscribe'
};
