import React, {useCallback, useContext, useRef, useState} from 'react';
import { string, object, func } from 'prop-types';
import { Form } from 'informed';
import {
    combine,
    validateEmail,
    validateConfirmPassword,
    isRequired,
    Field,
    TextInput
} from '@corratech/form-components';
import { PasswordField } from '@corratech/password-field';
import {
    AuthStore,
    createUser,
    LoaderStore,
    OptionsStore
} from '@corratech/context-provider';
import { useTranslation } from 'react-i18next';
import { Button, Alert } from 'react-bootstrap';
import { X as ErrorIcon } from 'react-feather';
require('./RegisterForm.less');
import { useDataLayerAction } from '@corratech/tag-manager';
// const PasswordField = React.lazy(() => import('@corratech/password-field'));
import { useReCaptchaStoreConfig } from 'ModulesPath/Checkout/useReCaptchaStoreConfig';
import { ReCaptcha } from 'react-recaptcha-v3';
import loadReCaptcha from '@corratech/google-recaptcha-v3/src/loadReCaptcha';
import { handleEmailChange } from 'UtilPath/handleEmailChange';
import { useDebouncedCallback } from 'UtilPath/useDebouncedCallback';

export const RegisterForm = props => {
    const [t] = useTranslation();
    const formApiRef = useRef(null);
    const setFormApi = useCallback(api => (formApiRef.current = api), []);

    const {
        className,
        css,
        enteredEmail,
        updateEmail,
        termsAndConditionsHandle
    } = props;

    const dataLayerAction = useDataLayerAction();

    const { dispatch, authState } = useContext(AuthStore);

    const LoadingIndicator = useContext(LoaderStore);

    const [password, setPassword] = useState('');

    const [passwordIsValid, setPasswordIsValid] = useState(false);

    const [accountCreateError, setAccountCreateError] = useState('');

    const [loading, setLoading] = useState(false);

    const isCheckout = window.location.pathname === '/checkout';

    //ReCaptcha
    const {
        actionV3PlaceRegister,
        actionV3PlaceSingIn
    } = useReCaptchaStoreConfig();
    const [reCaptchaTokenRegister, setReCaptchaTokenRegister] = useState(null);
    const [reCaptchaTokenSingIn, setReCaptchaTokenSingIn] = useState(null);

    let recaptchaV3Publickey = '';
    let isV3PlaceRegisterCaptchaEnable = false;
    let isV3PlaceSingInCaptchaEnable = false;

    const projectConfig = useContext(OptionsStore) || {};
    const storeConfig = projectConfig.storeConfig || {};
    if (storeConfig && storeConfig.recaptcha_v3_public_key) {
        recaptchaV3Publickey = storeConfig.recaptcha_v3_public_key;
    }

    if (actionV3PlaceRegister && actionV3PlaceRegister === 'recaptcha_v3') {
        isV3PlaceRegisterCaptchaEnable = true;
    }

    if (actionV3PlaceSingIn && actionV3PlaceSingIn === 'recaptcha_v3') {
        isV3PlaceSingInCaptchaEnable = true;
    }

    const verifyReCaptchaTokenRegisterCallback = token => {
        setReCaptchaTokenRegister(token);
        hideErrorMessage();
        setInterval(function() {
            resetReCaptchaTokenRegister();
        }, 100 * 1000);
    };

    const verifyReCaptchaTokenSingInCallback = token => {
        setReCaptchaTokenSingIn(token);
        hideErrorMessage();
        setInterval(function() {
            resetReCaptchaTokenSingIn();
        }, 110 * 1000);
    };

    const resetReCaptchaTokenRegister = () => {
        if (window.grecaptcha !== undefined) {
            window.grecaptcha
                .execute(recaptchaV3Publickey, { action: 'register' })
                .then(function(token) {
                    setReCaptchaTokenRegister(token);
                });
        }
    };

    const resetReCaptchaTokenSingIn = () => {
        if (window.grecaptcha !== undefined) {
            window.grecaptcha
                .execute(recaptchaV3Publickey, { action: 'sing_in' })
                .then(function(token) {
                    setReCaptchaTokenSingIn(token);
                });
        }
    };

    const onEmailChange = useDebouncedCallback(
        (value) => handleEmailChange(value, formApiRef, updateEmail, hideErrorMessage),
        300
    );

    const handleSubmit = formState => {
        setAccountCreateError('');
        hideErrorMessage();
        if (!passwordIsValid) {
            setAccountCreateError(
                'Passwords must be 8 characters long and include each of the following: lowercase letter, uppercase letter, number, and special character. Please re-enter your password and try again.'
            );
            return null;
        }

        formState.password = password;

        setLoading(true);

        dataLayerAction({
            type: 'FORM_SUBMIT',
            data: {
                formName: 'Register',
                formType: '',
                formCategory: ''
            }
        });

        createUser(
            {
                reCaptchaTokenRegister: reCaptchaTokenRegister,
                reCaptchaTokenSingIn: reCaptchaTokenSingIn,
                accountInfo: formState,
                dispatch: dispatch
            },
            (state, errorMessage = '') => {
                setLoading(false);
                setAccountCreateError(errorMessage);
            }
        );
        resetReCaptchaTokenRegister();
        resetReCaptchaTokenSingIn();
    };

    const hideErrorMessage = () => {
        dispatch({
            type: 'SET_AUTH_ERROR',
            error: null
        });
    };

    const termsAndConditionsClick = () => {
        termsAndConditionsHandle();
    };

    if (loading) {
        return <LoadingIndicator />;
    }

    return (
        <div
            aria-labelledby={'account-register-form-heading'}
            className={
                'account-register-form account-form-wrapper ' +
                (className || '')
            }
            css={css}
        >
            <div className={'title'}>
                {t('Please register by completing the form below.')}
            </div>
            {!!accountCreateError && (
                <Alert variant={'danger'}>
                    <ErrorIcon size={14} strokeWidth={'4'} color={'#B70020'} />
                    <strong>{t(`Error.`)}</strong> {accountCreateError}
                </Alert>
            )}
            {isV3PlaceRegisterCaptchaEnable &&
                loadReCaptcha(recaptchaV3Publickey)}

            {isV3PlaceRegisterCaptchaEnable && (
                <ReCaptcha
                    action="register"
                    sitekey={recaptchaV3Publickey}
                    verifyCallback={verifyReCaptchaTokenRegisterCallback}
                />
            )}
            {isV3PlaceSingInCaptchaEnable && (
                <ReCaptcha
                    action="sing_in"
                    sitekey={recaptchaV3Publickey}
                    verifyCallback={verifyReCaptchaTokenSingInCallback}
                />
            )}
            <Form onSubmit={handleSubmit} getApi={setFormApi}>
                <TextInput type="hidden" field="store_id" initialValue={8} />
                {isCheckout ? (
                    <TextInput
                        field="email"
                        inputMode="email"
                        type="email"
                        autoComplete="off"
                        placeholder={t(`Email`)}
                        initialValue={enteredEmail}
                        hidden={true}
                        id="email_address"
                        aria-required="true"
                        validate={combine([
                            {
                                fn: isRequired,
                                text: t(props.requiredText)
                            },
                            {
                                fn: validateEmail,
                                text: t(props.invalidEmailText)
                            }
                        ])}
                        validateOnBlur
                        onValueChange={onEmailChange}
                        data-cs-mask
                    />
                ) : (
                    <Field
                        label={t(`Email`)}
                        labelText={t(`email_address`)}
                        required={true}
                        hidden={true}
                    >
                        <TextInput
                            field="email"
                            inputMode="email"
                            type="email"
                            autoComplete="off"
                            placeholder={t(`Email`)}
                            initialValue={enteredEmail}
                            hidden={true}
                            id="email_address"
                            aria-required="true"
                            validate={combine([
                                {
                                    fn: isRequired,
                                    text: t(props.requiredText)
                                },
                                {
                                    fn: validateEmail,
                                    text: t(props.invalidEmailText)
                                }
                            ])}
                            validateOnBlur
                            onValueChange={onEmailChange}
                            data-cs-mask
                        />
                    </Field>
                )}
                <Field
                    label={t(`First Name`)}
                    labelText={t(`first_name`)}
                    required={true}
                >
                    <TextInput
                        field="firstname"
                        type="text"
                        autoComplete="given-name"
                        placeholder={t(`First Name`)}
                        id="first_name"
                        aria-required="true"
                        validate={combine([
                            {
                                fn: isRequired,
                                text: t(props.requiredText)
                            }
                        ])}
                        validateOnBlur
                    />
                </Field>
                <Field
                    label={t(`Last Name`)}
                    labelText={t(`last_name`)}
                    required={true}
                >
                    <TextInput
                        field="lastname"
                        type="text"
                        autoComplete="family-name"
                        placeholder={t(`Last Name`)}
                        id="last_name"
                        aria-required="true"
                        validate={combine([
                            {
                                fn: isRequired,
                                text: t(props.requiredText)
                            }
                        ])}
                        validateOnBlur
                    />
                </Field>
                <PasswordField
                    className={'input-password-wrapper'}
                    {...{
                        minLength: 8,
                        minScore: 2,
                        validate: {
                            isRequired: true,
                            validatePassword: true
                        }
                    }}
                    changeCallback={data => {
                        setPasswordIsValid(data.isValid);
                        setPassword(data.password);
                    }}
                    inputProps={{
                        name: 'password',
                        autoComplete: 'new-password',
                        placeholder: t(`Password`),
                        className: 'input-password'
                    }}
                    validatePasswordText={t(
                        'A password must contain at least 4 of the following: lowercase, uppercase, digits, special characters.'
                    )}
                />
                <Field
                    label={t(`Confirm Password`)}
                    labelText={t(`confirm_password`)}
                    required={true}
                >
                    <TextInput
                        field="confirm"
                        type="password"
                        placeholder={t(`Confirm Password`)}
                        id="confirm_password"
                        aria-required="true"
                        autoComplete="new-password"
                        validate={combine([
                            {
                                fn: isRequired,
                                text: t(props.requiredText)
                            },
                            {
                                fn: validateConfirmPassword,
                                text: t(props.passwordsMustMatchText)
                            }
                        ])}
                        validateOnChange
                        validateOnBlur
                    />
                </Field>

                <Button type="submit" size="lg" variant="primary" block>
                    {t(`Create Account`)}
                </Button>
            </Form>

            <div>
                {isV3PlaceRegisterCaptchaEnable && null !== authState.error && (
                    <Alert variant="danger">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: authState.error
                            }}
                        />
                    </Alert>
                )}
            </div>
            {!!termsAndConditionsHandle && (
                <div className={'terms-conditions-link-wrapper'}>
                    {t(`By clicking on Create Account, you accept our`)}{' '}
                    <Button
                        className={'terms-conditions-link'}
                        variant="link"
                        onClick={termsAndConditionsClick}
                    >
                        {t(`Terms & Conditions`)}
                    </Button>
                </div>
            )}
        </div>
    );
};

RegisterForm.propTypes = {
    className: string,
    css: object,
    enteredEmail: string,
    updateEmail: func.isRequired,
    requiredText: string,
    invalidEmailText: string,
    termsAndConditionsHandle: func
};

RegisterForm.defaultProps = {
    requiredText: 'This field is required',
    passwordsMustMatchText: 'Passwords must match',
    invalidEmailText: 'Please enter a valid email, such as example@example.com'
};
