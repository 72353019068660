import {
    AuthStore,
    CartStore,
    LoaderStore,
    useGlobalOptions,
    useSetNotification
} from '@corratech/context-provider';
import { CouponForm } from '@corratech/coupon-form';
import { GiftCard } from '@corratech/giftcard';
import { ScrollToTop } from '@corratech/scroll-to-top';
import { useWindowSize } from '@magento/peregrine';
import React, {useContext, useState, useEffect, useRef, useCallback} from 'react';
import { useLazyQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { Route } from 'react-router-dom';
import { TabbedPaymentMethodsForm } from '@corratech/loqate-tag-address';
import { CheckoutEstimationBlock } from '../CheckoutEstimationBlock';
import { FooterBlock } from '../FooterBlock';
import { CheckoutAccountForm } from '../forms';
import cartQ from '../graphql/cartQuery.graphql';
import { HeaderBlocker } from '../HeaderBlocker/HeaderBlocker';
import OrderConfirmation from '../OrderConfirmation/OrderConfirmation';
import {
    PaypalConfirmationPage,
    PaypalCancel,
    PaypalErrorRender
} from '@corratech/paypal-express';
import { PaypalExpressReturnFromMiniCart } from 'ModulesPath/PaypalExpressReturnFromMiniCart/PaypalExpressReturnFromMiniCart';
import { ProgressBar } from '../ProgressBar';
import { ShippingAuth, ShippingUnAuth } from '@corratech/loqate-tag-address';
import { SummaryBlock } from '../SummaryBlock';
import { AddressValidationCO } from '../AddressValidationCO';
import { useTitle } from 'react-use';
import { Info } from 'react-feather';
import './CheckoutPage.less';
import { EmptyCartCheckoutBlock } from '../EmptyCartCheckoutBlock/EmptyCartCheckoutBlock';
import { useHistory } from 'react-router-dom';
import { Button} from 'react-bootstrap';
import { useDataLayerAction } from '@corratech/tag-manager';
import applyCouponToCart from '../graphql/applyCouponToCart.graphql';
import removeCouponFromCart from '../graphql/removeCouponFromCart.graphql';
import { Error } from 'ModulesPath/Icons';
import { deepValue } from '@corratech/tag-manager';
import { FreeSamples } from '@corratech/free-samples';
import { FreeGifts } from '@corratech/free-gifts';
import { Util } from '@magento/peregrine';
import { PayPalPlaceOrderSuccess } from 'ModulesPath/PaypalExpressReturnFromMiniCart/PayPalPlaceOrderSuccess/PayPalPlaceOrderSuccess';
import { isOrderGroovePresent } from 'UtilPath/orderGroove';
import { DebugBearMonitoring } from 'ModulesPath/DebugBear/DebugBearMonitoring';
const { BrowserPersistence } = Util;
const storage = new BrowserPersistence();

export const CheckoutPage = ({
                                 DefaultLogo,
                                 IconCart,
                                 dropShip, // To handle New address text  and Save to address book checkbox display
                                 addressMsg, // To display a message in shipping address section
                                 shippingMsg, // To display a message in shipping method section
                                 warningIcon, // Custom Icon for warning messages
                                 noAddressForm, // prevent new address form display if no default address
                                 startSummaryOpen = false,
                                 startPaypalSummaryOpen = true,
                                 AddressValidationMethod = false,
                                 addressValidationName = false,
                                 TooltipIcon, // Custom Icon for tooltip messages
                                 EmptyCartCheckoutBlock,
                                 removeItemFromCartGraphql,
                                 ErrorIcon,
                                 showFreeSamples,
                                 showFreeGifts
                             }) => {
    const [t] = useTranslation();
    const options = useGlobalOptions();
    const [isSecondStep, setIsSecondStep] = useState(false);
    const dataLayerAction = useDataLayerAction();

    useEffect(() => {
        DebugBearMonitoring('Checkout');
    }, []);

    useTitle(
        t('Checkout') +
        ' - ' +
        (isSecondStep ? t('Review & Payment') : t('Shipping'))
    );

    return (
        <div className={'checkout-page'}>
            <HeaderBlocker
                DefaultLogo={DefaultLogo}
                isSecondStep={isSecondStep}
            />
            <Route
                path={'/checkout/paypal/return/'}
                component={() => (
                    //Both checkout and mincart flow is using the same component
                    <PaypalExpressReturnFromMiniCart
                        startPaypalSummaryOpen={startPaypalSummaryOpen}
                        dataLayerAction={dataLayerAction}
                    />
                )}
            />
            <Route
                path={'/checkout/paypal/returnfromcart/'}
                component={() => (
                    <PaypalExpressReturnFromMiniCart
                        startPaypalSummaryOpen={startPaypalSummaryOpen}
                        dataLayerAction={dataLayerAction}
                    />
                )}
            />
            <Route path={'/checkout/paypal/success/'}>
                <PayPalPlaceOrderSuccess dataLayerAction={dataLayerAction} />
            </Route>
            <Route
                path={'/checkout/paypal/cancel/'}
                component={() => <PaypalCancel />}
            />
            <Route exact path={'/checkout'}>
                <ScrollToTop>
                    <RenderCheckoutPage
                        projectConfig={options}
                        setIsSecondStep={setIsSecondStep}
                        dataLayerAction={dataLayerAction}
                        startSummaryOpen={startSummaryOpen}
                        startPaypalSummaryOpen={startPaypalSummaryOpen}
                        AddressValidationMethod={AddressValidationMethod}
                        addressValidationName={addressValidationName}
                        IconCart={IconCart}
                        dropShip={dropShip}
                        addressMsg={addressMsg}
                        shippingMsg={shippingMsg}
                        warningIcon={warningIcon}
                        noAddressForm={noAddressForm}
                        TooltipIcon={TooltipIcon}
                        EmptyCartCheckoutBlock={EmptyCartCheckoutBlock}
                        removeItemFromCartGraphql={removeItemFromCartGraphql}
                        ErrorIcon={ErrorIcon}
                        showFreeSamples={showFreeSamples}
                        showFreeGifts={showFreeGifts}
                    />
                </ScrollToTop>
            </Route>
            <FooterBlock config={options} />
        </div>
    );
};

const RenderCheckoutPage = ({
                                projectConfig,
                                setIsSecondStep,
                                dataLayerAction,
                                startSummaryOpen,
                                AddressValidationMethod,
                                addressValidationName,
                                IconCart,
                                dropShip,
                                addressMsg,
                                shippingMsg,
                                warningIcon,
                                noAddressForm,
                                TooltipIcon,
                                EmptyCartCheckoutBlock,
                                removeItemFromCartGraphql,
                                ErrorIcon,
                                showFreeSamples,
                                showFreeGifts
                            }) => {
    const [t] = useTranslation();
    const history = useHistory();
    const { cartState, dispatch } = useContext(CartStore);
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const shippingMethodRef = useRef(null);
    const [invalidShippingMsg, setInvalidShippingMsg] = useState(false);
    const [invalidShippingAddress, setInvalidShippingAddress] = useState(false);

    const [enteredPassword, setEnteredPassword] = useState('');
    const [joinRewards, setJoinRewards] = useState(false);
    const isOGPresent = isOrderGroovePresent(cartState);
    const [shouldShowRegisterForm, setShouldShowRegisterForm] = useState(cartState.shouldShowRegisterForm);
    const [isSubscriptionPaymentError, setIsSubscriptionPaymentError] = useState(false);

    const [executeCartQuery, { loading }] = useLazyQuery(cartQ, {
        fetchPolicy: 'no-cache',
        onCompleted: res => {
            const updatedCart = res.cart;
            if (res.cart.is_virtual) {
                updatedCart.isBillingSameAsShipping = false;
            }
            dispatch({
                type: 'SET_CART',
                cart: updatedCart,
            });
        },
    });

    const cartQuery = useCallback(
        options => {
            if (!cartState.cartId) {
                return;
            }

            executeCartQuery({
                ...options,
                variables: {
                    cartId: cartState.cartId,
                    isSignedIn: !!cartState.cart.authenticated,
                    ...(options && options.variables),
                },
            });
        },
        [cartState.cartId, cartState.cart.authenticated, executeCartQuery]
    );

    /**
     * Handle payapl rejection case if history contains
     * payal error state redirect user back to
     * payment step and show error message
     */
    useEffect(() => {
        if (
            !loading &&
            history.location.paypalError &&
            history.location.paypalError.hasError &&
            cartState.cart &&
            cartState.cart.items &&
            cartState.cart.items.length > 0 &&
            !showPaymentForm &&
            !cartState.placedOrder
        ) {
            if (
                cartState.cart.shipping_addresses &&
                cartState.cart.shipping_addresses.length > 0 &&
                cartState.cart.shipping_addresses[0].selected_shipping_method
            ) {
                setShowPaymentForm(true);
            }
        }
    }, [history.location, loading]);

    useEffect(() => {
        //Make sure we have most updated cart info on initial checkout page load
        if (cartState.cartId || (!cartState.cartId && !cartState.placedOrder)) {
            cartQuery();
        }
    }, []);

    document.title =
        t('Checkout - ') +
        (cartState.cart.is_virtual ? t('Review & Payment') : t('Shipping'));

    useEffect(() => {
        if (
            cartState.cart &&
            showPaymentForm &&
            !cartState.placedOrder &&
            (!cartState.cart.shipping_addresses ||
                !cartState.cart.shipping_addresses[0] ||
                !cartState.cart.shipping_addresses[0].selected_shipping_method)
        ) {
            setShowPaymentForm(false);
            setInvalidShippingMsg(
                'Your shipping method has been reset. Please choose a shipping method to proceed.'
            );
        }
    }, [cartState]);

    useEffect(() => {
        setShowPaymentForm(false);
        setCheckoutStepNameUrlHash('shipping');
        setShouldShowRegisterForm(cartState.shouldShowRegisterForm);
    }, [cartState.shouldShowRegisterForm]);

    useEffect(() => {
        if (showPaymentForm) {
            let selectedShippingMethod = deepValue(
                cartState,
                [
                    'cart',
                    'shipping_addresses',
                    '0',
                    'selected_shipping_method',
                    'method_title'
                ],
                null
            );
            if (selectedShippingMethod) {
                dataLayerAction({
                    type: 'CHECKOUT_OPTION',
                    data: {
                        step: 1,
                        option: selectedShippingMethod
                    }
                });
            }
            setIsSecondStep(true);
        } else {
            setIsSecondStep(false);
        }
    }, [showPaymentForm]);

    const { authState } = useContext(AuthStore);

    const [authStateIsLoading, setAuthStateIsLoading] = useState(false);
    const LoadingIndicator = useContext(LoaderStore);

    // Step name in the URL is used in the dataLayer tracking
    const setCheckoutStepNameUrlHash = stepName => {
        let {
            location: { pathname, hash, search }
        } = window;
        let newHash = !!stepName ? `#${stepName}` : '';
        if ((!!hash || !!newHash) && hash !== newHash) {
            window.history.replaceState(
                null,
                null,
                `${pathname}${search}${newHash}`
            );
        }
    };

    const windowSize = useWindowSize();
    const isMobile = windowSize.innerWidth <= projectConfig.viewport.mobile;

    if ((cartState.isLoading || loading) && !cartState.placedOrder) {
        return <LoadingIndicator />;
    }

    // This is the first page, Shipping Info
    if (
        cartState.cart.items &&
        cartState.cart.items.length > 0 &&
        !showPaymentForm &&
        !cartState.placedOrder &&
        !cartState.cart.is_virtual
    ) {
        setCheckoutStepNameUrlHash('shipping');
        return (
            <>
                <h1 className={'checkout-main-title sr-only'}>
                    {t('Checkout')}
                </h1>
                <div className={'checkout-container container-width'}>
                    {isMobile ? (
                        <CheckoutEstimationBlock IconCart={IconCart} />
                    ) : (
                        ''
                    )}
                    <div className="left-column">
                        {!isMobile ? (
                            <ProgressBar
                                activeStepNumber={1}
                                setShowPaymentForm={setShowPaymentForm}
                            />
                        ) : (
                            ''
                        )}
                        {history.location.paypalError &&
                            !history.location.paypalError.hasError && (
                                <PaypalErrorRender
                                    paypal={history.location.paypalError}
                                />
                            )}
                        <CheckoutAccountForm
                            TooltipIcon={TooltipIcon}
                            setAuthStateIsLoading={setAuthStateIsLoading}
                            passwordAutoComplete={
                                invalidShippingMsg ? false : true
                            }
                            setEnteredPassword={setEnteredPassword}
                            enteredPassword={enteredPassword}
                            joinRewards={joinRewards}
                            setJoinRewards={setJoinRewards}
                            isSubscriptionPaymentError={isSubscriptionPaymentError}
                        />

                        {authStateIsLoading ? (
                            <LoadingIndicator />
                        ) : !!authState.token ? (
                            <ShippingAuth
                                setShowPaymentForm={setShowPaymentForm}
                                dataLayerAction={dataLayerAction}
                                dropShip={dropShip}
                                addressMsg={addressMsg}
                                shippingMsg={shippingMsg}
                                warningIcon={warningIcon}
                                noAddressForm={noAddressForm}
                                TooltipIcon={TooltipIcon}
                                shippingMethodRef={shippingMethodRef}
                                invalidShippingMsg={invalidShippingMsg}
                                setInvalidShippingMsg={setInvalidShippingMsg}
                                invalidShippingAddress={invalidShippingAddress}
                                setInvalidShippingAddress={
                                    setInvalidShippingAddress
                                }
                            />
                        ) : (
                            !authState.token &&
                            !isOGPresent &&
                            !isSubscriptionPaymentError && (
                                <ShippingUnAuth
                                    setShowPaymentForm={setShowPaymentForm}
                                    dataLayerAction={dataLayerAction}
                                    TooltipIcon={TooltipIcon}
                                    shippingMethodRef={shippingMethodRef}
                                    invalidShippingMsg={invalidShippingMsg}
                                    warningIcon={warningIcon}
                                    setInvalidShippingMsg={
                                        setInvalidShippingMsg
                                    }
                                    invalidShippingAddress={
                                        invalidShippingAddress
                                    }
                                    setInvalidShippingAddress={
                                        setInvalidShippingAddress
                                    }
                                />
                            )
                        )}
                    </div>
                    <div className={'cart-info'}>
                        <SummaryBlock
                            startSummaryOpen={startSummaryOpen}
                            isOGPresent={isOGPresent || isSubscriptionPaymentError}
                        />
                    </div>
                </div>
            </>
        );
    }
    // this is the second page, billing info
    else if (
        (cartState.cart && showPaymentForm && !cartState.placedOrder) ||
        cartState.cart.is_virtual
    ) {
        setCheckoutStepNameUrlHash('payment');
        return (
            <ScrollToTop>
                <div className={'checkout-page'}>
                    <div className={'checkout-container container-width'}>
                        {isMobile ? (
                            <CheckoutEstimationBlock IconCart={IconCart} />
                        ) : (
                            ''
                        )}
                        <div className="left-column">
                            {cartState.cart.is_virtual ? (
                                <>
                                    {!isMobile ? (
                                        <ProgressBar
                                            activeStepNumber={1}
                                            setShowPaymentForm={
                                                setShowPaymentForm
                                            }
                                            isVirtual={true}
                                        />
                                    ) : (
                                        ''
                                    )}
                                </>
                            ) : (
                                <>
                                    {!isMobile ? (
                                        <ProgressBar
                                            activeStepNumber={2}
                                            setShowPaymentForm={
                                                setShowPaymentForm
                                            }
                                        />
                                    ) : (
                                        ''
                                    )}
                                </>
                            )}
                            {history.location.paypalError &&
                                history.location.paypalError.hasError && (
                                    <PaypalErrorRender
                                        paypal={history.location.paypalError}
                                    />
                                )}
                            {!cartState.cart.is_virtual &&
                                AddressValidationMethod && (
                                    <AddressValidationCO
                                        setShowPaymentForm={setShowPaymentForm}
                                        AddressValidationMethod={
                                            AddressValidationMethod
                                        }
                                    />
                                )}
                            {cartState.cart.is_virtual && (
                                <CheckoutAccountForm
                                    TooltipIcon={TooltipIcon}
                                    setAuthStateIsLoading={
                                        setAuthStateIsLoading
                                    }
                                    setEnteredPassword={setEnteredPassword}
                                    enteredPassword={enteredPassword}
                                    joinRewards={joinRewards}
                                    setJoinRewards={setJoinRewards}
                                    isSubscriptionPaymentError={isSubscriptionPaymentError}
                                />
                            )}
                            <div className={'gift-card-checkout'}>
                                <GiftCard />
                            </div>
                            <div className={'promo-block-checkout'}>
                                <CouponForm
                                    applyCouponToCartGraphql={applyCouponToCart}
                                    removeCouponFromCartGraphql={
                                        removeCouponFromCart
                                    }
                                    promoTitle={'ADD A PROMOTIONAL CODE'}
                                    successMsg1={'Code'}
                                    ErrorIcon={
                                        <Error size={9} color={'#B30015'} />
                                    }
                                />
                            </div>
                            {showFreeGifts && (
                                <FreeGifts
                                    ErrorIcon={ErrorIcon}
                                    removeItemFromCartGraphql={
                                        removeItemFromCartGraphql
                                    }
                                />
                            )}
                            {showFreeSamples && (
                                <FreeSamples
                                    ErrorIcon={ErrorIcon}
                                    removeItemFromCartGraphql={
                                        removeItemFromCartGraphql
                                    }
                                />
                            )}
                            <TabbedPaymentMethodsForm
                                dataLayerAction={dataLayerAction}
                                addressValidationName={addressValidationName}
                                invalidShippingAddress={invalidShippingAddress}
                                setInvalidShippingAddress={
                                    setInvalidShippingAddress
                                }
                                setShowPaymentForm={setShowPaymentForm}
                                setIsSubscriptionPaymentError={setIsSubscriptionPaymentError}
                            />
                        </div>
                        <div className={'cart-info'}>
                            <SummaryBlock
                                startSummaryOpen={!!startSummaryOpen}
                                setShowPaymentForm={setShowPaymentForm}
                                showPaymentForm={showPaymentForm}
                                loading={loading}
                                isOGPresent={isOGPresent || isSubscriptionPaymentError}
                            />
                        </div>
                    </div>
                </div>
            </ScrollToTop>
        );
    }
    // this is the third page, order confirmation
    else if (cartState.placedOrder) {
        setCheckoutStepNameUrlHash('success');
        return (
            <OrderConfirmation
                setEnteredPassword={setEnteredPassword}
                enteredPassword={enteredPassword}
                joinRewards={joinRewards}
                setJoinRewards={setJoinRewards}
            />
        );
    }
    // this is the error page. It should never show.
    else {
        setCheckoutStepNameUrlHash();
        return (
            <div
                className={'checkout-container container-width'}
                style={{ justifyContent: 'center' }}
            >
                <h1 className={'checkout-title'}>
                    <EmptyCartCheckoutBlock />
                </h1>
            </div>
        );
    }
};

CheckoutPage.defaultProps = {
    TooltipIcon: <Button variant="success" className="tooltip-btn"><Info size={16} /></Button>,
    EmptyCartCheckoutBlock: EmptyCartCheckoutBlock,
    showFreeSamples: true,
    showFreeGifts: true
};
